export const tariffsReInitSlider = (element, detachEventListeners, attachEventListeners) => {
    document.querySelectorAll('.switch-theme__btn').forEach((tab) => {
        tab.addEventListener('click', () => {
            document.querySelectorAll(element).forEach((container) => {
                detachEventListeners(container)
                attachEventListeners(container)
            })
        })
    })
}
